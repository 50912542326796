import { render, staticRenderFns } from "./weapp.vue?vue&type=template&id=c2482f26&"
import script from "./weapp.vue?vue&type=script&lang=js&"
export * from "./weapp.vue?vue&type=script&lang=js&"
import style0 from "./weapp.vue?vue&type=style&index=0&id=c2482f26&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Java\\healthy\\healthy-frame-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c2482f26')) {
      api.createRecord('c2482f26', component.options)
    } else {
      api.reload('c2482f26', component.options)
    }
    module.hot.accept("./weapp.vue?vue&type=template&id=c2482f26&", function () {
      api.rerender('c2482f26', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/weapp/weapp.vue"
export default component.exports